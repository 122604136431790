import api from "./api";

type GetEventTransactionsParams = {
  walletId: string;
  level0ResourceId: string;
  level1ResourceId: string;
  eventGroups: string[];
  eventTypes: string[];
  references: string[];
  includeParentId: boolean;
  limit: number;
  page: number;
};

export const getEventTransactions = async (
  params?: Partial<GetEventTransactionsParams>,
): Promise<any> => {
  const { data } = await api.get("/eventTransactions", { params });

  return data;
};

export const getEventGroups = async (): Promise<any> => {
  const { data } = await api.get("/eventGroups");

  return data.eventGroups;
};

export const getEventTypes = async (): Promise<any> => {
  const { data } = await api.get("/eventTypes");

  return data.eventTypes;
};

export const getEventTransactionsByWalletId = async (
  walletId: string,
): Promise<any> => {
  const { data } = await api.get("/eventTransactions", {
    params: {
      walletId,
    },
  });

  return data.eventTransactions;
};

export const getMeterEventTransactions = async (params: {
  fromDate?: Date;
  toDate?: Date;
  level1ResourceId?: string;
}): Promise<any> => {
  const { data } = await api.get("/eventTransactions/meters", {
    params: {
      ...params,
    },
  });

  return data.eventTransactions;
};
