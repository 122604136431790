import React from "react";
import { useTranslation } from "react-i18next";
import HandleGoBackOrClose from "@components/shared/HandleGoBackOrClose";
import type { ConfirmData } from "@components/shared/ConfirmationDetail";
import { useStep, UseStepHelpers } from "@hooks/useStep";
import {
  GetInfoMessagesFunction,
  useStepStatuses,
} from "@hooks/useStepStatuses";
import { formatDate } from "@utils/formatDate";

type Subscriber = {
  id: string;
  name: string;
  accountNumber: string;
  walletId: string;
};

type WRSHirachy = {
  id: string;
  name: string;
  identifier: string;
};

type TransferDetail = {
  subscriber: Subscriber;
  level0Resource: WRSHirachy;
  waterClass: {
    id: string;
    name: string;
  };
  extractionRights: { name: string }[];
};

type Details = {
  id: string;
  price: string;
  description: string;
  usageIn: string;
  level1wrs: WRSHirachy;
  from: TransferDetail;
  to: TransferDetail;
  transferVolume: string;
  effectiveDate: Date;
};

type ContextValue = {
  currentStep: number;
  stepHelpers: UseStepHelpers;
  handleChangeDetails: HandleChangeDetails;
  handleSelectFromSubscriber: (subscriber: Subscriber) => void;
  details: Details;
  navigateForCancel: () => void;
  workflowCompleted: boolean;
  setWorkflowCompleted: React.Dispatch<boolean>;
  workflowInstances: any[];
  setWorkflowInstances: React.Dispatch<React.SetStateAction<any[]>>;
  validateVolume: (
    fieldName: string,
    type?: "Invalid" | "Volume" | "Success",
    availableVolume?: number,
    entitlementName?: string,
    level0wrsName?: string,
    waterClassName?: string,
  ) => void;
  validatePrice: (volume: number) => void;
  validateBuyer: (selectedBuyer: any) => void;
  getInfoMessages: GetInfoMessagesFunction;
  resetBuyer: () => void;
  resetTransferVolume: () => void;
  transferIds: string[];
  setTransferIds: React.Dispatch<React.SetStateAction<string[]>>;
  info: Record<string, () => ConfirmData>;
  isSameAccount: boolean;
  resetModifyStatus: (step: number) => void;
};

type HandleChangeDetails = <TKey extends keyof Details>(
  key: TKey,
  value: any,
  subKey?: string,
) => void;

const SeasonalTransferContext = React.createContext<ContextValue | undefined>(
  undefined,
);

const initialValue: TransferDetail = {
  subscriber: {
    id: "",
    name: "",
    accountNumber: "",
    walletId: "",
  },
  level0Resource: {
    id: "",
    name: "",
    identifier: "",
  },
  waterClass: {
    id: "",
    name: "",
  },
  extractionRights: [],
};

const initialDetails: Details = {
  id: "",
  price: "",
  description: "",
  usageIn: "Irrigation",
  level1wrs: { id: "", name: "", identifier: "" },
  from: { ...initialValue },
  to: { ...initialValue },
  transferVolume: "",
  effectiveDate: new Date(),
};

function SeasonalTransferProvider({ children }: { children: React.ReactNode }) {
  const { t } = useTranslation();
  const handleGoBackOrClose = HandleGoBackOrClose();
  const maxStep = 9;
  const [currentStep, stepHelpers] = useStep(maxStep);
  const {
    modifyStatuses,
    getInfoMessages,
    reset: resetModifyStatus,
  } = useStepStatuses(maxStep);
  const [transferIds, setTransferIds] = React.useState<string[]>([]);
  const [details, setDetails] = React.useState({ ...initialDetails });
  const [workflowCompleted, setWorkflowCompleted] = React.useState(false);
  const [workflowInstances, setWorkflowInstances] = React.useState<any[]>([]);

  const handleChangeDetails: HandleChangeDetails = (key, value, subKey) => {
    setDetails((prevState: any) => {
      const updatedDetails = { ...prevState };
      if (subKey) {
        updatedDetails[key] = { ...prevState[key], [subKey]: value };
      } else {
        updatedDetails[key] = value;
      }
      return updatedDetails;
    });
  };

  const handleSelectFromSubscriber = (subscriber: Subscriber) => {
    setDetails(
      (prev): Details => ({
        ...prev,
        from: {
          ...initialValue,
          subscriber,
        },
      }),
    );
    resetBuyer();
    resetTransferVolume();
  };

  const navigateForCancel = handleGoBackOrClose;

  const validatePrice = (price?: number) => {
    if (price === undefined || price === 0) {
      modifyStatuses({
        stepNumber: 5,
        fieldName: "transferPrice",
        infoType: "error",
        message: t(
          "approval.seasonal_water_assignments.create.step_5.error_1",
        ) as string,
      });
    } else {
      modifyStatuses({
        stepNumber: 5,
        fieldName: "transferPrice",
      });
    }
  };

  const validateBuyer = (selectedBuyer: any) => {
    if (selectedBuyer === undefined) {
      modifyStatuses({
        stepNumber: 3,
        fieldName: "buyer",
      });
      return;
    }

    const waterClassNames = selectedBuyer?.extractionRights
      ?.filter(
        (e: any) =>
          e.level0Resource.identifier ===
          details.from.level0Resource.identifier,
      )
      .map((i: any) => i.waterClass.name);
    const isSameWaterClass = waterClassNames.includes(
      details.from.waterClass.name,
    );

    if (isSameWaterClass) {
      modifyStatuses({
        stepNumber: 3,
        fieldName: "buyer",
        infoType: "success",
        message: t(
          "approval.seasonal_water_assignments.create.step_4.success_1",
        ) as string,
      });
    } else {
      modifyStatuses({
        stepNumber: 3,
        fieldName: "buyer",
        infoType: "error",
        message: t(
          "approval.seasonal_water_assignments.create.step_4.error_1",
        ) as string,
      });
    }
  };

  const validateVolume = (
    fieldName: string,
    type?: "Invalid" | "Volume" | "Success",
    availableVolume = 0,
    entitlementName = "",
    level0wrsName = "",
    waterClassName = "",
  ) => {
    switch (type) {
      case "Invalid":
        modifyStatuses({
          stepNumber: 2,
          fieldName,
          infoType: "error",
          message: t(
            "approval.seasonal_water_assignments.create.step_3.error_2",
            { entitlementName, level0wrsName, waterClassName },
          ) as string,
        });
        break;
      case "Volume":
        modifyStatuses({
          stepNumber: 2,
          fieldName,
          infoType: "error",
          message: t(
            "approval.seasonal_water_assignments.create.step_3.error_1",
            {
              availableVolume,
              entitlementName,
              level0wrsName,
              waterClassName,
            },
          ) as string,
        });
        break;
      case "Success":
        modifyStatuses({
          stepNumber: 2,
          fieldName,
          infoType: "success",
          message: t(
            "approval.seasonal_water_assignments.create.step_3.success_1",
            {
              entitlementName,
              level0wrsName,
              waterClassName,
            },
          ) as string,
        });
        break;
      default:
        modifyStatuses({
          stepNumber: 2,
          fieldName,
          message: "",
        });
        break;
    }
  };

  const resetBuyer = () => {
    handleChangeDetails(
      "to",
      { ...initialDetails.to.subscriber },
      "subscriber",
    );
    validateBuyer(undefined);
  };

  const resetTransferVolume = () => {
    handleChangeDetails("transferVolume", "");
    resetModifyStatus(2);
  };

  const isSameAccount = details.from.subscriber.id === details.to.subscriber.id;

  const info = {
    level1Resource: () => {
      return {
        title: t("level1wrs.create.details"),
        body: [
          {
            key: t("common.level1wrs"),
            value: `${details.level1wrs?.name} (${details.level1wrs.identifier})`,
          },
        ],
      };
    },
    fromSubscriber: () => {
      return {
        title: t("approval.seasonal_water_assignments.create.step_2.details"),
        body: [
          {
            key: t("approval.seasonal_water_assignments.from_subscriber"),
            value: `${details.from.subscriber.name} (${details.from.subscriber.accountNumber})`,
          },
        ],
      };
    },
    volumes: () => {
      return {
        title: t("approval.seasonal_water_assignments.create.step_3.details"),
        body: [
          {
            key: t("common.level0wrs"),
            value: details.from.level0Resource.identifier,
          },
          {
            key: t("common.water_class"),
            value: details.from.waterClass.name,
          },
          {
            key: t("common.volume"),
            value: `${details.transferVolume} ${t("common.volume_unit")}`,
          },
        ],
      };
    },
    toSubscriber: () => {
      return {
        title: t("approval.seasonal_water_assignments.create.step_4.details"),
        body: [
          {
            key: t("approval.seasonal_water_assignments.to_subscriber"),
            value: `${details.to.subscriber.name} (${details.to.subscriber.accountNumber})`,
          },
        ],
      };
    },
    destination: () => {
      return {
        title: t("approval.seasonal_water_assignments.destination"),
        body: [
          {
            key: t("common.level0wrs"),
            value: details.to.level0Resource.identifier,
          },
          {
            key: t("common.water_class"),
            value: details.to.waterClass.name,
          },
        ],
        disableEdit: !isSameAccount,
      };
    },
    details: () => {
      return {
        title: t("approval.seasonal_water_assignments.create.step_5.details"),
        body: [
          {
            key: t("common.price"),
            value: `${t("common.currency")}${details.price || 0}`,
          },
          ...(details.description
            ? [
                {
                  key: t(
                    "approval.seasonal_water_assignments.create.step_5.reason_zero_trade",
                  ),
                  value: details.description,
                },
              ]
            : []),
        ],
      };
    },
    effectiveDate: () => {
      return {
        title: t("date.effective.set"),
        body: [
          {
            key: t("date.effective.title"),
            value: formatDate(details.effectiveDate),
          },
        ],
      };
    },
  };

  const value: ContextValue = {
    currentStep,
    stepHelpers,
    handleChangeDetails,
    handleSelectFromSubscriber,
    details,
    navigateForCancel,
    workflowCompleted,
    setWorkflowCompleted,
    workflowInstances,
    setWorkflowInstances,
    validateVolume,
    validatePrice,
    getInfoMessages,
    validateBuyer,
    resetBuyer,
    resetTransferVolume,
    transferIds,
    setTransferIds,
    info,
    isSameAccount,
    resetModifyStatus,
  };

  return (
    <SeasonalTransferContext.Provider value={value}>
      {children}
    </SeasonalTransferContext.Provider>
  );
}

const useSeasonalTransferContext = () => {
  const context = React.useContext(SeasonalTransferContext);
  if (context === undefined) {
    throw new Error(
      "useSeasonalTransferContext must be used within a SeasonalTransferProvider",
    );
  }
  return context;
};

export { SeasonalTransferProvider, useSeasonalTransferContext };
