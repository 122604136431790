import React from "react";
import { useTranslation } from "react-i18next";
import HandleGoBackOrClose from "@components/shared/HandleGoBackOrClose";
import { formatDate } from "@utils/formatDate";
import { type ConfirmData } from "@components/shared/ConfirmationDetail";

const initialtDetails = {
  level1Resource: {
    id: "",
    name: "",
  },
  level0Resource: {
    id: "",
    identifier: "",
  },
  subscriber: {
    id: "",
    name: "",
    accountNumber: "",
    walletId: "",
  },
  extractionRight: {
    id: "",
    name: "",
  },
  effectiveDate: new Date(),
  reason: "",
  volume: "",
};

type Details = typeof initialtDetails;

type ContextValue = {
  details: Details;
  setDetails: React.Dispatch<React.SetStateAction<Details>>;
  isComplete: boolean;
  setIsComplete: React.Dispatch<boolean>;
  workflowInstance: any;
  setWorkflowInstance: React.Dispatch<any>;
  handleCancel: () => void;
  info: Record<
    "scheme" | "subscriber" | "extractionRight" | "details" | "effectiveDate",
    ConfirmData
  >;
};

const StockAndDomesticApprovalContext = React.createContext<
  ContextValue | undefined
>(undefined);

const StockAndDomesticApprovalProvider = ({
  children,
}: {
  children: React.ReactNode;
}) => {
  const { t } = useTranslation();
  const handleGoBackOrClose = HandleGoBackOrClose();
  const [details, setDetails] = React.useState<Details>(initialtDetails);
  const [isComplete, setIsComplete] = React.useState(false);
  const [workflowInstance, setWorkflowInstance] = React.useState<any>();

  const handleCancel = handleGoBackOrClose;

  const info = {
    scheme: {
      title: t("common.level1wrs"),
      body: [
        {
          key: t("common.name"),
          value: details.level1Resource.name,
        },
      ],
    },
    subscriber: {
      title: t("common.subscriber"),
      body: [
        {
          key: t("common.name"),
          value: `${details.subscriber.name} (${details.subscriber.accountNumber})`,
        },
      ],
    },
    extractionRight: {
      title: t("common.extraction_rights"),
      body: [
        {
          key: t("extraction_right.name"),
          value: details.extractionRight.name,
        },
      ],
    },
    details: {
      title: t("common.details"),
      body: [
        {
          key: t("common.volume"),
          value: `${details.volume} ${t("common.volume_unit")}`,
        },
        {
          key: t("approval.stock_and_domestic.enter_reason"),
          value: details.reason || "-",
        },
      ],
    },
    effectiveDate: {
      title: t("date.effective.set"),
      body: [
        {
          key: t("date.effective.title"),
          value: formatDate(details.effectiveDate),
        },
      ],
    },
  };

  const values = {
    details,
    setDetails,
    isComplete,
    setIsComplete,
    workflowInstance,
    setWorkflowInstance,
    handleCancel,
    info,
  };

  return (
    <StockAndDomesticApprovalContext.Provider value={values}>
      {children}
    </StockAndDomesticApprovalContext.Provider>
  );
};

function useStockAndDomesticApprovalContext() {
  const context = React.useContext(StockAndDomesticApprovalContext);
  if (context === undefined) {
    throw new Error(
      "useStockAndDomesticApprovalContext must be used within a StockAndDomesticApprovalProvider",
    );
  }
  return context;
}

export { StockAndDomesticApprovalProvider, useStockAndDomesticApprovalContext };
