import {
  FunctionComponent,
  ReactNode,
  createContext,
  useContext,
  useState,
} from "react";
import { useStep, UseStepHelpers } from "@hooks/useStep";
import { useNavigate, useSearchParams } from "react-router-dom";

export enum BalanceAdjustmentType {
  WATER_HARVESTING = 1,
  METER_READING = 2,
}

type BalanceAdjustmentTypeData = {
  type: BalanceAdjustmentType;
  level1Resource: any;
  subscriber: any;
  extractionRight: any;
  declarations: any[];
  adjustment: number;
  notes?: string;
  effectiveDate: Date;
  file?: File;
};

const initialData = {
  type: BalanceAdjustmentType.WATER_HARVESTING,
  level1Resource: {
    id: "",
    identifier: "",
  },
  subscriber: {
    id: "",
    name: "",
  },
  extractionRight: {
    id: "",
    name: "",
  },
  declarations: [],
  adjustment: 0,
  notes: "",
  effectiveDate: new Date(),
};

type ContextValue = {
  currentStep: number;
  stepHelpers: UseStepHelpers;
  balanceAdjustmentData: BalanceAdjustmentTypeData;
  setBalanceAdjustmentData: any;
  workflowInstance: any;
  setWorkflowInstance: React.Dispatch<any>;
  navigateForCancel: () => void;
  [key: string]: any;
};

const BalanceAdjustmentContext = createContext<ContextValue>(
  {} as ContextValue,
);

const BalanceAdjustmentProvider: FunctionComponent<{
  children: ReactNode;
}> = ({ children }) => {
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  const [balanceAdjustmentData, setBalanceAdjustmentData] =
    useState(initialData);
  const [workflowInstance, setWorkflowInstance] = useState<any>();
  const maxStep = 8;
  const [currentStep, stepHelpers] = useStep(maxStep);

  const navigateForCancel = () =>
    !!searchParams.get("newWindow")
      ? window.close()
      : navigate("/polestar/administrative_approvals");

  const value: ContextValue = {
    currentStep,
    stepHelpers,
    balanceAdjustmentData,
    setBalanceAdjustmentData,
    workflowInstance,
    setWorkflowInstance,
    navigateForCancel,
  };

  return (
    <BalanceAdjustmentContext.Provider value={value}>
      {children}
    </BalanceAdjustmentContext.Provider>
  );
};

function useBalanceAdjustmentContext() {
  const context = useContext(BalanceAdjustmentContext);
  if (context === undefined) {
    throw new Error(
      "useBalanceAdjustmentContext must be used within a BalanceAdjustmentContext",
    );
  }
  return context;
}

export { BalanceAdjustmentProvider, useBalanceAdjustmentContext };
