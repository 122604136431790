import { BalanceAdjustmentType } from "@context/BalanceAdjustmentContext";
import api from "./api";

export const getBalanceAdjustments = async (
  params?: Record<string, any>,
): Promise<any> => {
  const { data } = await api.get("/admin/balanceAdjustments", { params });

  return data.balanceAdjustments;
};

export const createBalanceAdjustment = async (balanceAdjustmentData: {
  subscriberId: string;
  level0ResourceId: string;
  declarations: any[];
  type: BalanceAdjustmentType;
  notes?: string;
  effectiveDate: Date;
}) => {
  const { data } = await api.post(
    "/admin/balanceAdjustments",
    balanceAdjustmentData,
  );

  return [data.balanceAdjustment, data.workflowInstance];
};

export const adjustDeclaration = async (payload: {
  subscriberId: string;
  level0ResourceId: string;
  declarationId: string;
  reading: number;
  volume: number;
  notes?: string;
}) => {
  const { data } = await api.post(
    "/admin/balanceAdjustments/declaration",
    payload,
  );

  return [data.balanceAdjustment, data.workflowInstance];
};
