import { FunctionComponent } from "react";
import InfoPanel from "@components/form/InfoPanel";
import WorkflowPanels from "@components/form/WorkflowPanels";
import CreateBalanceAdjustmentEnterData from "@components/form/balance_adjustments/CreateBalanceAdjustmentEnterData";
import CreateBalanceAdjustmentSelectExtractionRight from "@components/form/balance_adjustments/CreateBalanceAdjustmentSelectExtractionRight";
import CreateBalanceAdjustmentSelectScheme from "@components/form/balance_adjustments/CreateBalanceAdjustmentSelectScheme";
import CreateBalanceAdjustmentSelectSubscriber from "@components/form/balance_adjustments/CreateBalanceAdjustmentSelectSubscriber";
import CreateBalanceAdjustmentUploadEvidence from "@components/form/balance_adjustments/CreateBalanceAdjustmentUploadEvidence";
import CreateBalanceAdjustmentLedger from "@components/form/balance_adjustments/CreateBalanceAdjustmentLedger";
import Layout from "@components/layout/Layout";
import StepButton from "@components/shared/StepButton";
import {
  BalanceAdjustmentProvider,
  BalanceAdjustmentType,
  useBalanceAdjustmentContext,
} from "@context/BalanceAdjustmentContext";
import { formatVolume } from "@utils/formatVolume";
import { useTranslation } from "react-i18next";
import CreateBalanceAdjustmentSelectDeclaration from "@components/form/balance_adjustments/CreateBalanceAdjustmentSelectDeclaration";
import CreateBalanceAdjustmentCompleted from "@components/form/balance_adjustments/CreateBalanceAdjustmentCompleted";
import { sumBy } from "lodash";
import { useSearchParams } from "react-router-dom";
import { useSubscriber } from "@hooks/query/useSubscriber";
import EffectiveDateForm from "@components/form/EffectiveDateForm";
import AdministrativeApprovalEffectiveDateInformation from "@components/shared/AdministrativeApprovalEffectiveDateInformation";

const CreateBalanceAdjustment: FunctionComponent = () => {
  return (
    <BalanceAdjustmentProvider>
      <MainComponent />
    </BalanceAdjustmentProvider>
  );
};

const MainComponent: FunctionComponent = () => {
  const { t } = useTranslation();
  const [searchParams] = useSearchParams();
  const {
    currentStep,
    stepHelpers,
    balanceAdjustmentData,
    setBalanceAdjustmentData,
    navigateForCancel,
  } = useBalanceAdjustmentContext();

  useSubscriber(searchParams.get("subscriberId")!, {
    enabled: !!searchParams.get("subscriberId"),
    refetchOnWindowFocus: false,
    onSuccess: (subscriber: any) => {
      if (subscriber) {
        setBalanceAdjustmentData({
          ...balanceAdjustmentData,
          type: BalanceAdjustmentType.WATER_HARVESTING,
          level1Resource: subscriber.level1WRS,
          subscriber,
        });
      }
    },
  });

  return (
    <Layout
      permissions={["CreateBalanceAdjustments"]}
      breadcrumb={[
        {
          label: t("dashboard.dashboard"),
          href: "/polestar",
        },
        {
          label: t("balance_adjustment.water_harvesting.title"),
        },
      ]}
      accountingPeriodLevel1ResourceId={
        balanceAdjustmentData?.level1Resource?.id
      }
      title={t("balance_adjustment.water_harvesting.title")}
    >
      <WorkflowPanels
        selectedStep={currentStep}
        steps={[
          {
            label: (
              <StepButton
                index={0}
                currentStep={currentStep}
                info={
                  <ul>
                    <li>
                      <strong>{t("common.level1wrs")}:</strong>{" "}
                      {balanceAdjustmentData.level1Resource?.name}
                    </li>
                  </ul>
                }
              >
                {t("balance_adjustment.select_level1wrs")}
              </StepButton>
            ),
            panel: (
              <CreateBalanceAdjustmentSelectScheme
                onSave={stepHelpers.goToNextStep}
                onGoBack={stepHelpers.goToPrevStep}
              />
            ),
            infoPanel: (
              <InfoPanel>
                <div className="space-y-2">
                  <p>{t("balance_adjustment.create.select_level1wrs_info1")}</p>
                </div>
              </InfoPanel>
            ),
          },
          {
            label: (
              <StepButton
                index={1}
                currentStep={currentStep}
                info={
                  <ul>
                    <li>
                      <strong>{t("common.subscriber")}:</strong>{" "}
                      {balanceAdjustmentData.subscriber?.name}
                    </li>
                  </ul>
                }
              >
                {t("balance_adjustment.select_subscriber")}
              </StepButton>
            ),
            panel: (
              <CreateBalanceAdjustmentSelectSubscriber
                onSave={stepHelpers.goToNextStep}
                onGoBack={stepHelpers.goToPrevStep}
              />
            ),
            infoPanel: (
              <InfoPanel>
                <div className="space-y-2">
                  <p>
                    {t("balance_adjustment.create.select_subscriber_info1")}
                  </p>
                </div>
              </InfoPanel>
            ),
          },
          {
            label: (
              <StepButton
                index={2}
                currentStep={currentStep}
                info={
                  <ul>
                    <li>
                      <strong>{t("common.level0wrs")}:</strong>{" "}
                      {
                        balanceAdjustmentData.extractionRight?.level0Resource
                          ?.identifier
                      }
                    </li>
                  </ul>
                }
              >
                {t("balance_adjustment.select_extraction_right")}
              </StepButton>
            ),
            panel: (
              <CreateBalanceAdjustmentSelectExtractionRight
                onSave={stepHelpers.goToNextStep}
                onGoBack={stepHelpers.goToPrevStep}
              />
            ),
            infoPanel: (
              <InfoPanel>
                <div className="space-y-2">
                  <p>
                    {t(
                      "balance_adjustment.create.select_extraction_right_info1",
                    )}
                  </p>
                </div>
              </InfoPanel>
            ),
          },
          {
            label: (
              <StepButton
                index={3}
                currentStep={currentStep}
                info={
                  <ul>
                    <li>
                      <strong>{t("common.meter")}:</strong>{" "}
                      {balanceAdjustmentData.declarations
                        ?.map((d) => d.meter?.serialNo)
                        .join(", ")}
                    </li>
                  </ul>
                }
              >
                {t("balance_adjustment.select_declaration")}
              </StepButton>
            ),
            panel: (
              <CreateBalanceAdjustmentSelectDeclaration
                onSave={stepHelpers.goToNextStep}
                onGoBack={stepHelpers.goToPrevStep}
              />
            ),
            infoPanel: (
              <InfoPanel>
                <div className="space-y-2">
                  <p>
                    {t("balance_adjustment.create.select_declaration_info1")}
                  </p>
                </div>
              </InfoPanel>
            ),
          },
          {
            label: (
              <StepButton
                index={4}
                currentStep={currentStep}
                info={
                  <ul>
                    <li>
                      <strong>
                        {t("balance_adjustment.create.adjustment")}:
                      </strong>{" "}
                      {sumBy(
                        balanceAdjustmentData.declarations,
                        (d: any) => d.adjustment,
                      ) > 0 && "+"}
                      {formatVolume(
                        sumBy(balanceAdjustmentData.declarations, "adjustment"),
                      )}
                    </li>
                  </ul>
                }
              >
                {t("balance_adjustment.enter_adjustment")}
              </StepButton>
            ),
            panel: (
              <CreateBalanceAdjustmentEnterData
                onSave={stepHelpers.goToNextStep}
                onGoBack={stepHelpers.goToPrevStep}
              />
            ),
            infoPanel:
              balanceAdjustmentData.type ===
              BalanceAdjustmentType.WATER_HARVESTING ? (
                <InfoPanel>
                  <div className="space-y-2">
                    <p>
                      {t("balance_adjustment.create.enter_adjustment_info1")}
                    </p>
                    <p>
                      {t("balance_adjustment.create.enter_adjustment_info2")}
                    </p>
                    <ul className="list-disc pl-4">
                      <li>
                        {t("balance_adjustment.create.enter_adjustment_info3")}
                      </li>
                      <li>
                        {t("balance_adjustment.create.enter_adjustment_info4")}
                      </li>
                      <li>
                        {t("balance_adjustment.create.enter_adjustment_info5")}
                      </li>
                    </ul>
                  </div>
                </InfoPanel>
              ) : (
                <InfoPanel>
                  <div className="space-y-2">
                    <p>
                      {t(
                        "balance_adjustment.create.enter_meter_adjustment_info1",
                      )}
                    </p>
                    <p>
                      {t(
                        "balance_adjustment.create.enter_meter_adjustment_info2",
                      )}
                    </p>
                    <ul className="list-disc pl-4">
                      <li>
                        {t(
                          "balance_adjustment.create.enter_meter_adjustment_info3",
                        )}
                      </li>
                      <li>
                        {t(
                          "balance_adjustment.create.enter_meter_adjustment_info4",
                        )}
                      </li>
                    </ul>
                  </div>
                </InfoPanel>
              ),
          },
          {
            label: (
              <StepButton
                index={5}
                currentStep={currentStep}
                info={
                  <ul>
                    <li>
                      <strong>
                        {t("balance_adjustment.create.adjustment")}:
                      </strong>{" "}
                      {sumBy(
                        balanceAdjustmentData.declarations,
                        (d: any) => d.adjustment,
                      ) > 0 && "+"}
                      {formatVolume(
                        sumBy(balanceAdjustmentData.declarations, "adjustment"),
                      )}
                    </li>
                  </ul>
                }
              >
                {t("date.effective.set")}
              </StepButton>
            ),
            panel: (
              <EffectiveDateForm
                level1ResourceId={balanceAdjustmentData.level1Resource.id}
                showHeadAndFooter
                onDateChange={(date) => {
                  setBalanceAdjustmentData({
                    ...balanceAdjustmentData,
                    effectiveDate: date,
                  });
                }}
                effectiveDate={balanceAdjustmentData.effectiveDate}
                onNext={stepHelpers.goToNextStep}
                onPrevious={stepHelpers.goToPrevStep}
                onCancel={() => navigateForCancel()}
              />
            ),
            infoPanel: (
              <AdministrativeApprovalEffectiveDateInformation
                workflowTitle={t(
                  "date.effective.info_panel_by_trade.water_harvesting",
                )}
              />
            ),
          },
          {
            label: (
              <StepButton
                index={6}
                currentStep={currentStep}
                info={
                  <ul>
                    <li>
                      <strong>
                        {t("balance_adjustment.create.file_field")}:
                      </strong>{" "}
                      {balanceAdjustmentData.file?.name || "-"}
                    </li>
                  </ul>
                }
              >
                {t("balance_adjustment.upload_file")}
              </StepButton>
            ),
            panel: (
              <CreateBalanceAdjustmentUploadEvidence
                onSave={stepHelpers.goToNextStep}
                onGoBack={stepHelpers.goToPrevStep}
              />
            ),
            infoPanel: (
              <InfoPanel>
                <div className="space-y-2">
                  <p>{t("balance_adjustment.create.upload_file_info1")}</p>
                  <p>{t("balance_adjustment.create.upload_file_info2")}</p>
                  <p>{t("balance_adjustment.create.upload_file_info3")}</p>
                </div>
              </InfoPanel>
            ),
          },
          {
            label: (
              <StepButton index={7} currentStep={currentStep}>
                {t("balance_adjustment.ledger")}
              </StepButton>
            ),
            panel: (
              <CreateBalanceAdjustmentLedger
                onSave={stepHelpers.goToNextStep}
                onGoBack={stepHelpers.goToPrevStep}
              />
            ),
            infoPanel: (
              <InfoPanel
                successes={[t("balance_adjustment.create.ledger_info1")]}
              />
            ),
          },
          {
            label: (
              <StepButton index={8} currentStep={currentStep}>
                {t("common.ledger")}
              </StepButton>
            ),
            panel: <CreateBalanceAdjustmentCompleted />,
            infoPanel: (
              <InfoPanel
                successes={[
                  t("balance_adjustment.create.completed_info1", {
                    adjustment:
                      (sumBy(
                        balanceAdjustmentData.declarations,
                        (d: any) => d.adjustment,
                      ) > 0
                        ? "+"
                        : "") +
                      formatVolume(
                        sumBy(balanceAdjustmentData.declarations, "adjustment"),
                      ),
                    level0ResourceIdentifier:
                      balanceAdjustmentData.extractionRight?.level0Resource
                        ?.identifier,
                    subscriberName: balanceAdjustmentData.subscriber?.name,
                    subscriberAccountNumber:
                      balanceAdjustmentData.subscriber?.accountNumber,
                  }),
                ]}
              />
            ),
          },
        ]}
      />
    </Layout>
  );
};

export default CreateBalanceAdjustment;
