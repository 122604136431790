import { useQuery } from "@tanstack/react-query";

import { getAllSubscriberLevel0Resources } from "@services/subscriber";

export const useAllSubscriberLevel0Resources = (
  subscriberId: string,
  options?: Record<string, any>
) => {
  return useQuery<any>({
    queryKey: ["subscriberLevel0Resources", { subscriberId }],
    queryFn: () => getAllSubscriberLevel0Resources(subscriberId),
    enabled: Boolean(subscriberId),
    ...options,
  });
};
