import api from "./api";

export const getCurrentAccountingPeriodCheck = async ({
  params,
}: {
  params?: Record<string, any>;
}) => {
  const { data } = await api.get("/admin/accountingPeriodChecks/", { params });
  return data.accountingPeriodCheck;
};

export const saveCurrentAccountingPeriodCheck = async (params: any) => {
  const { data } = await api.post("/admin/accountingPeriodChecks/", params);

  return data.accountingPeriodCheck;
};

export const requestResetAccountingPeriod = async (params: any) => {
  const { data } = await api.put("/admin/accountingPeriodChecks", {
    ...params,
    status: 1,
  });

  return data.accountingPeriodCheck;
};
export type ResetAccountingPeriodPayload = {
  level1ResourceId: string;
  canceledAdministrativeApprovalIds: string[];
};
export const resetAccountingPeriod = async (
  payload: ResetAccountingPeriodPayload,
) => {
  const { data } = await api.post("/admin/accountingPeriodChecks/reset", {
    ...payload,
  });

  return [data.accountingPeriod, data.workflowInstance];
};
