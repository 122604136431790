import React from "react";
import Select from "react-select";
import { groupBy, orderBy } from "lodash";
import {
  Link,
  useNavigate,
  useParams,
  useSearchParams,
} from "react-router-dom";
import { ArrowDownIcon, ArrowUpIcon } from "@heroicons/react/20/solid";
import { useTranslation } from "react-i18next";

import Table from "@components/layout/Table";
import Label from "@components/form/Label";
import SearchInput from "@components/form/SearchInput";
import MapView from "@components/shared/MapView";
import DeclarationTag from "@components/shared/DeclarationTag";
import ExtractionPointStatusTags from "@components/shared/ExtractionPointStatusTags";
import Tag from "@components/shared/Tag";
import Loading from "@components/shared/Loading";
import { useAllDeclarations } from "@hooks/query/useAllDeclarations";
import { useSubscriber } from "@hooks/query/useSubscriber";
import { useAppContext } from "@context/AppContext";
import { useAllExtractionPoints } from "@hooks/query/useAllExtractionPoints";
import { formatVolume } from "@utils/formatVolume";
import { formatDate } from "@utils/formatDate";
import { parseLatLng } from "@utils/parseLatLng";
import ENV from "@config/env";

const ExtractionPointTab: React.FunctionComponent = () => {
  const { t } = useTranslation();
  const { setInfoPanel, checkPermissions } = useAppContext();
  const { id: subscriberId = "", level0ResourceId = "" } = useParams();
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  const [filter, setFilter] = React.useState({
    extractionPointName: searchParams.get("extractionPointName") ?? "",
  });

  const { data: subscriber = {}, isLoading: isSubscriberLoading } =
    useSubscriber(subscriberId);
  const level1ResourceId = subscriber.level1ResourceId;
  const { data: points = [], isLoading: isPointsLoading } =
    useAllExtractionPoints({
      params: {
        level0ResourceId,
        definedByWalletId: subscriber.walletId,
      },
      options: {
        enabled: Boolean(level0ResourceId) && Boolean(subscriber.walletId),
      },
    });

  const { data: declarations = [] } = useAllDeclarations({
    params: {
      extractionPointIds: points.map((i: any) => i.id),
    },
    enabled: points.length > 0,
  });

  React.useEffect(() => {
    setInfoPanel();
  }, [setInfoPanel]);

  if (isSubscriberLoading || isPointsLoading) {
    return (
      <div className="pt-20">
        <Loading />
      </div>
    );
  }

  const filteredExtractionPoints = points.filter(
    (point: any) =>
      filter.extractionPointName === "" ||
      point.name
        .toLowerCase()
        .includes(filter.extractionPointName.toLowerCase())
  );
  const orderExtractionPoints = orderBy(
    filteredExtractionPoints,
    ["isActive", "name"],
    ["desc", "asc"]
  );

  const declarationGroupByPoint = groupBy(declarations, "extractionPointId");

  return (
    <>
      <header className="flex flex-col gap-3 md:flex-row md:items-center md:justify-between">
        <form className="basis-80">
          <Label htmlFor="extractionPointName">
            {t("extraction_point.filter_name")}
          </Label>
          <SearchInput
            id="extractionPointName"
            onChange={(e) =>
              setFilter((prev) => ({
                ...prev,
                extractionPointName: e.target.value,
              }))
            }
            value={filter.extractionPointName || ""}
            placeholder={t("common.search") as string}
          />
        </form>

        <div className="flex gap-2 items-center">
          {checkPermissions(["CreateExtractionPoint"]) && (
            <Link
              to={`/polestar/subscribers/${subscriber.id}/level0_resources/${level0ResourceId}/extraction_points/create?subscriberId=${subscriber.id}&level0ResourceId=${level0ResourceId}`}
              className="btn-secondary text-xs rounded"
            >
              {t("extraction_point.new_extraction_point")}
            </Link>
          )}
          {checkPermissions(["CreateMeters"]) && (
            <Link
              to={`/polestar/subscribers/${subscriber.id}/level0_resources/${level0ResourceId}/meters/create?level1ResourceId=${level1ResourceId}`}
              className="btn-secondary text-xs rounded"
            >
              {t("meter.new_meter")}
            </Link>
          )}
        </div>
      </header>
      <div className="mt-4 overflow-auto">
        <ul className="space-y-4">
          {orderExtractionPoints?.length ? (
            orderExtractionPoints.map((point: any) => {
              const latLong = parseLatLng(point?.address?.location);
              const hasMeter = Boolean(point?.meter);
              const isActive = point.isActive;
              const canDeclareMeterRead =
                checkPermissions(["CreateDeclarations"]) && isActive;
              const pointDeclarations = declarationGroupByPoint[point.id] ?? [];

              return (
                <li key={point.id}>
                  <div className="grid grid-cols-10 place-content-between">
                    <div className="col-span-7 border border-gray-300 rounded p-4">
                      <header className="flex flex-row gap-3 flex-wrap items-center text-gray-600 text-xs whitespace-nowrap">
                        <div>
                          {t("extraction_point.name")} #{point.name}
                        </div>
                        <div className="flex flex-row items-center gap-2">
                          {t("extraction_point.is_active")}:
                          <ExtractionPointStatusTags point={point} />
                        </div>
                        {hasMeter ? (
                          <div>
                            {t("meter.serial_no")}: {point.meter?.serialNo}
                          </div>
                        ) : null}
                        <div>
                          {t("extraction_point.source")}:{" "}
                          {point.source.toLowerCase()}
                        </div>
                        <div>
                          {t("extraction_point.lat")}:{" "}
                          {latLong?.lat?.toFixed(5) ?? "-"}
                        </div>
                        <div>
                          {t("extraction_point.long")}:{" "}
                          {latLong?.lng?.toFixed(5) ?? "-"}
                        </div>
                        {ENV.CLIENT_ID === "seqwater" && (
                          <>
                            <div>
                              {t("extraction_point.group")}:{" "}
                              {point.group ?? "-"}
                            </div>
                            <div>
                              {t("extraction_point.sequence")}:{" "}
                              {point.sequence ?? "-"}
                            </div>
                          </>
                        )}
                      </header>

                      <div className="flex justify-end gap-2 items-center mt-1 mb-3">
                        {checkPermissions(["LinkExtractionPoint"]) ? (
                          <Link
                            to={`/polestar/subscribers/${subscriber.id}/level0_resources/${point.level0ResourceId}/extraction_points/link?extractionPointId=${point.id}`}
                            className="btn-secondary text-xs py-2 px-3 rounded"
                          >
                            {t("extraction_point.link_offtake")}
                          </Link>
                        ) : null}
                        {checkPermissions(["UnlinkExtractionPoint"]) ? (
                          <Link
                            to={`/polestar/level1wrs/${level1ResourceId}/extraction_points/unlink?subscriberId=${subscriber?.id}&level0ResourceId=${level0ResourceId}&extractionPointId=${point.id}`}
                            className="btn-outline-primary text-xs py-2 px-3 rounded"
                          >
                            {t("extraction_point.unlink.title")}
                          </Link>
                        ) : null}
                        <Select
                          className="text-sm w-40"
                          placeholder={t("common.actions") as string}
                          options={[
                            {
                              label: t("extraction_point.edit"),
                              value: `/polestar/subscribers/${subscriber?.id}/level0_resources/${level0ResourceId}/extraction_points/${point.id}/edit?subscriberId=${subscriber?.id}`,
                              disabled:
                                !checkPermissions(["UpdateExtractionPoint"]) ||
                                !isActive,
                            },
                            {
                              label: t("meter.new_meter"),
                              onClick: () => {
                                navigate(
                                  `/polestar/subscribers/${subscriber.id}/level0_resources/${level0ResourceId}/meters/create?level1ResourceId=${level1ResourceId}&extractionPointId=${point.id}`
                                );
                              },
                              disabled:
                                !checkPermissions(["CreateMeters"]) ||
                                hasMeter ||
                                !isActive ||
                                !point.isActive,
                              value: `/polestar/subscribers/${subscriber.id}/level0_resources/${level0ResourceId}/meters/create?level1ResourceId=${level1ResourceId}&extractionPointId=${point.id}`,
                            },
                            {
                              label: t("meter.edit.title"),
                              value: `/polestar/meters/${point?.meter?.id}/edit`,
                              disabled:
                                checkPermissions(["UpdateMeters"]) || !hasMeter,
                            },
                            {
                              label: hasMeter
                                ? t("declaration.declare_meter_reading")
                                : t("declaration.unmetered_usage.title"),
                              value: `/polestar/subscribers/${subscriber.id}/level0_resources/${level0ResourceId}/declarations/create?&subscriberId=${subscriber.id}&extractionPointId=${point?.id}`,
                              disabled: !canDeclareMeterRead,
                            },
                            {
                              label: t("meter.replace"),
                              value: `/polestar/subscribers/${subscriber.id}/level0_resources/${level0ResourceId}/meters/replace?subscriberId=${subscriber.id}&extractionPointId=${point.id}`,
                              disabled:
                                !checkPermissions([
                                  "UpdateExtractionPoint",
                                  "UpdateMeters",
                                  "CreateDeclarations",
                                ]) ||
                                !hasMeter ||
                                !isActive,
                            },
                            {
                              label: t("meter.deactivate"),
                              value: `/polestar/subscribers/${subscriber.id}/level0_resources/${level0ResourceId}/meters/decommission?subscriberId=${subscriber.id}&extractionPointId=${point.id}`,
                              disabled:
                                !checkPermissions([
                                  "UpdateExtractionPoint",
                                  "CreateDeclarations",
                                  "DecommissionMeters",
                                ]) ||
                                !hasMeter ||
                                !isActive,
                            },
                            {
                              label: t(
                                "extraction_point.link_meter.action_label"
                              ),
                              value: `/polestar/subscribers/${subscriber.id}/level0_resources/${level0ResourceId}/extraction_points/link_meter?subscriberId=${subscriber.id}&extractionPointId=${point.id}`,
                              disabled: !point.isActive || hasMeter,
                            },
                            {
                              label: t("meter.replace_capsule.title"),
                              value: `/polestar/level1wrs/${level1ResourceId}/meters/${point.meter?.id}/replace_capsule?subscriberId=${subscriber.id}&extractionPointId=${point.id}`,
                              disabled: !canDeclareMeterRead || !hasMeter,
                            },
                            {
                              label: t(
                                "balance_adjustment.meter_reading.action"
                              ),
                              value: `/polestar/balance_adjustments/declaration?level1ResourceId=${level1ResourceId}&meterId=${point?.meter?.id}`,
                              disabled: !canDeclareMeterRead || !hasMeter,
                            },
                          ].filter((i) => !Boolean(i.disabled))}
                          onChange={(e: any) => {
                            if (e?.value) {
                              navigate(e.value);
                            }
                          }}
                          menuPortalTarget={document.body}
                          styles={{
                            menuPortal: (base) => ({
                              ...base,
                              fontSize: "14px",
                            }),
                          }}
                        />
                      </div>

                      <Table
                        containerClassName="rounded-none md:rounded-none text-xs"
                        fields={[
                          {
                            title: t("meter.serial_no"),
                            name: "meterId",
                          },
                          ...(ENV.CLIENT_ID === "seqwater"
                            ? [
                                {
                                  title: t("meter.meter_status"),
                                  name: "meterStatus",
                                },
                              ]
                            : []),
                          {
                            title: t("declaration.read_at"),
                            name: "readAt",
                          },
                          {
                            title: t("declaration.reading"),
                            name: "reading",
                          },
                          {
                            title: t("common.balance"),
                            name: "volume",
                          },
                          {
                            title: t("common.tag"),
                            name: "tag",
                          },
                          {
                            title: t("common.created_by"),
                            name: "createdBy",
                          },
                        ]}
                        data={pointDeclarations.map((declaration: any) => {
                          const isDecommissioned =
                            declaration.meter?.deletedAt &&
                            !declaration.meter.isActive;
                          const isActive =
                            declaration.meter?.isActive &&
                            declaration.meter.id === point.meter?.id;
                          const volume = declaration.requireManualIntervention
                            ? 0
                            : declaration.volume;
                          const balance = -1 * volume;
                          return {
                            ...declaration,
                            meterId:
                              declaration.meter?.serialNo ??
                              t("meter.unmetered"),
                            meterStatus: (
                              <Tag status={isActive ? "success" : "error"}>
                                {isDecommissioned
                                  ? t("common.decommission")
                                  : isActive
                                  ? t("common.active")
                                  : t("common.inactive")}
                              </Tag>
                            ),
                            readAt: formatDate(new Date(declaration.readAt)),
                            reading: declaration.meter?.serialNo
                              ? formatVolume(declaration.reading, "")
                              : "-",
                            volume: (
                              <Tag
                                status={
                                  balance < 0
                                    ? "error"
                                    : balance > 0
                                    ? "success"
                                    : "default"
                                }
                                className="inline-flex gap-1 items-center pl-1 pr-2"
                              >
                                <span>
                                  {balance < 0 && (
                                    <ArrowDownIcon className="w-4 h-4" />
                                  )}
                                  {balance > 0 && (
                                    <ArrowUpIcon className="w-4 h-4" />
                                  )}
                                </span>
                                {formatVolume(balance)}
                              </Tag>
                            ),
                            tag: declaration.tag ? (
                              <DeclarationTag value={declaration.tag} />
                            ) : null,
                            createdBy: declaration?.createdByUser
                              ? declaration.createdByUser.isAdmin
                                ? t("common.admin")
                                : t("common.user")
                              : t("common.system"),
                          };
                        })}
                        noRowsText={t("declaration.no_declaration")}
                        pageSize={5}
                      />
                    </div>
                    <div className="col-span-3 pl-4 h-80">
                      {latLong.isValid ? (
                        <MapView
                          zoomLevel={14}
                          markers={[
                            { lat: latLong.lat ?? 0, lng: latLong.lng ?? 0 },
                          ]}
                        />
                      ) : (
                        <div className="font-semibold text-primary-3 text-center text-sm pt-10">
                          {t("shared.map.invalid_data")}
                        </div>
                      )}
                    </div>
                  </div>
                </li>
              );
            })
          ) : (
            <li>
              <div className="py-6 whitespace-nowrap text-gray-400 text-center">
                {t("extraction_point.no_extraction_point_data")}
              </div>
            </li>
          )}
        </ul>
      </div>
    </>
  );
};

export default ExtractionPointTab;
