import { useEffect, useState } from "react";
import { isFunction, sumBy } from "lodash";
import { useTranslation } from "react-i18next";
import Select from "react-select";
import { Link, useNavigate, useSearchParams } from "react-router-dom";
import { Switch } from "@headlessui/react";
import classNames from "classnames";

import Table from "@components/layout/Table";
import SearchInput from "@components/form/SearchInput";
import Label from "@components/form/Label";
import { useAppContext } from "@context/AppContext";
import { useRequestAmalgamationOrSubdivision } from "@hooks/mutation/useRequestAmalgamation";
import { isValidationError } from "@utils/formError";
import {
  AdministrativeApprovalType,
  ExtractionRightApprovalType,
} from "@services/administrativeApprovals";
import { toastError } from "@utils/toast";
import Tag from "@components/shared/Tag";
import {
  CanAmalgamate,
  getExtractionRightsCanAmalgamate,
} from "@utils/getExtractionRightsCanAmalgamate";
import ConfirmModal from "@components/shared/ConfirmModal";
import ConfirmUpdateExtractionRightStatusModal from "@components/modal/ConfirmUpdateExtractionRightStatusModal";
import { formatVolume } from "@utils/formatVolume";
import { formatDate } from "@utils/formatDate";
import { useAllExtractionRights } from "@hooks/query/useAllExtractionRights";

type Status = "active" | "inactive" | "disconnected";

type Filter = {
  subscriberNameOrAccNo: string;
  extractionRightName: string;
  level0ResourceId: string;
  status?: Status;
};

type Level1WRSExtractionRightListProps = {
  level1Resource: any;
  canAmalgamate: CanAmalgamate;
  setCanAmalgamate: (value: CanAmalgamate) => void;
};

const Level1WRSExtractionRightList: React.FunctionComponent<
  Level1WRSExtractionRightListProps
> = ({ level1Resource, canAmalgamate, setCanAmalgamate }) => {
  const { t } = useTranslation();
  const { checkPermissions } = useAppContext();
  const [selectedExtractionRightIds, setSelectedExtractionRightIds] = useState<
    string[]
  >([]);
  const [subdivisionRightId, setSubdivisionRightId] = useState("");
  const [confirmModalString, setConfirmModalString] = useState<
    AdministrativeApprovalType.AME | AdministrativeApprovalType.SDE | ""
  >("");
  const [showDeletedRecords, setShowDeletedRecords] = useState(false);
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  const extractionRightName = searchParams.get("extractionRightName") ?? "";
  const [filter, setFilter] = useState<Filter>({
    subscriberNameOrAccNo: "",
    level0ResourceId: "",
    extractionRightName,
  });
  const [updateExtractionRightStatus, setUpdateExtractionRightStatus] =
    useState({
      id: "",
      name: "",
      isActive: false,
    });

  const {
    data: extractionRights = [],
    isLoading,
    refetch: getExtractionRights,
  } = useAllExtractionRights({
    params: {
      level1ResourceId: level1Resource?.id,
      isActive:
        filter.status === "active"
          ? true
          : filter.status === "inactive"
          ? false
          : undefined,
    },
    options: {
      refetchOnWindowFocus: false,
      enabled: Boolean(level1Resource),
    },
  });
  const { mutateAsync: requestAmalgamationMutation } =
    useRequestAmalgamationOrSubdivision();

  const level1ResourceOptions =
    extractionRights
      ?.map((right: any) => ({
        label: right.level0Resource?.identifier,
        value: right.level0Resource?.id,
      }))
      .filter(
        (right: any, i: number, self: any[]) =>
          self.findIndex((r: any) => r.value === right.value) === i
      ) || [];

  const statusOptions: {
    label: string;
    value: Status;
  }[] = [
    { label: t("common.active"), value: "active" },
    { label: t("common.inactive"), value: "inactive" },
    {
      label: t("extraction_right.not_connected_to_extraction_point"),
      value: "disconnected",
    },
  ];

  const handleFilterChange = <TField extends keyof Filter>(
    field: TField,
    value: Filter[TField]
  ) => {
    setFilter({
      ...filter,
      [field]: value,
    });
  };

  const handleOnConfirm = async ({
    type,
    rightId,
  }: {
    type: AdministrativeApprovalType.AME | AdministrativeApprovalType.SDE;
    rightId?: string;
  }) => {
    const selectedRights = extractionRights?.filter(
      (i: any) =>
        selectedExtractionRightIds.some((id: any) => i.id === id) ||
        i.id === rightId
    );

    try {
      const administrativeApproval = await requestAmalgamationMutation({
        level1ResourceId: level1Resource.id,
        extractionRightIds: [
          ...(rightId ? [rightId] : selectedExtractionRightIds),
        ],
        level0ResourceId: selectedRights[0]?.level0ResourceId,
        waterClassId: selectedRights[0]?.waterClassId,
        buyerId: selectedRights[0]?.subscriber.id,
        volume: sumBy(selectedRights, "volume"),
        type,
      });

      navigate(
        `/polestar/administrative_approvals/amalgamate_or_subdivide?administrativeApprovalId=${administrativeApproval.id}&from=level1wrs`
      );
    } catch (error: any) {
      const toastErrorMessage = t(
        "level1wrs.tab_info_panel.extraction_right.toast_error"
      );
      if (isValidationError(error)) {
        const { errors = [] } = error?.response?.data;
        const messages = errors.map((i: any) => i.message);

        toastError(
          <>
            <p>{toastErrorMessage}</p>
            {messages.length ? (
              <ul className="list-disc pl-4">
                {messages.map((text: any) => {
                  return <li key={text}>{text}</li>;
                })}
              </ul>
            ) : null}
          </>
        );
      } else {
        toastError(
          <>
            <p>{toastErrorMessage}</p>
            <p>{error?.message}</p>
          </>
        );
      }
    }
  };

  const fields = [
    {
      title: t("subscriber.account_number"),
      name: "accountNumber",
    },
    {
      title: t("subscriber.name"),
      name: "accountName",
    },
    {
      title: t("extraction_right.purpose"),
      name: "purpose",
    },
    {
      title: t("extraction_right.name"),
      name: "name",
    },
    {
      title: t("common.water_class"),
      name: "waterClassName",
    },
    {
      title: `${t("extraction_right.volume")} (${t("common.volume_unit")})`,
      name: "volume",
    },
    {
      title: t("common.level0wrs"),
      name: "level0WRSIdentifier",
    },
    {
      title: t("subscriber.created_at"),
      name: "createdAt",
    },
    {
      title: t("common.status"),
      name: "status",
    },
    {
      title: t("common.actions"),
      name: "actions",
    },
  ];

  const tableData = extractionRights
    ?.map((right: any) => {
      const options = [
        {
          label: t("common.view"),
          value: `/polestar/subscribers/${right.subscriber?.id}/level0_resources/${right.level0ResourceId}/extraction_rights?name=${right.name}`,
          disabled: false,
        },
        {
          label: t("common.update"),
          value: `/polestar/level1wrs/${level1Resource?.id}/extraction_rights/${right.id}/edit`,
          disabled:
            !checkPermissions(["UpdateExtractionRight"]) ||
            !right.isActive ||
            right.deletedAt,
        },
        {
          label: t(
            "approval.subdivide_and_amalgamate.create.short_title_subdivide"
          ),
          disabled:
            !checkPermissions(["CreateSubdivision"]) ||
            selectedExtractionRightIds.length > 1 ||
            !right.isActive ||
            right.deletedAt,
          callback: () => {
            setSubdivisionRightId(right.id);
            setConfirmModalString(AdministrativeApprovalType.SDE);
          },
        },
        {
          label: t("extraction_right.permanent_trades"),
          value: `/polestar/administrative_approvals/permanent_trade?level1ResourceId=${level1Resource?.id}&sellerId=${right?.subscriber?.id}`,
          disabled:
            !checkPermissions(["UpdateExtractionRight"]) ||
            !right.isActive ||
            right.deletedAt,
        },
        {
          label: right.isActive ? t("common.deactivate") : t("common.activate"),
          callback: () => {
            setUpdateExtractionRightStatus({
              id: right.id,
              name: right.name,
              isActive: right.isActive,
            });
          },
          disabled:
            !checkPermissions(["UpdateExtractionRightStatus"]) ||
            right.deletedAt,
        },
        {
          label: t("common.delete"),
          value: `/polestar/level1wrs/${level1Resource?.id}/extraction_rights/${right.id}/delete`,
          disabled:
            !checkPermissions(["DeleteExtractionRight"]) || right.deletedAt,
        },
      ].filter((i) => !i.disabled);
      const isLinked = right?.rightFractions.some((i: any) => i.isLinked);
      return {
        ...right,
        accountNumber: (
          <Link
            to={`/polestar/subscribers/${right.subscriber?.id}`}
            className="text-sm text-primary-2 underline"
          >
            {right.subscriber?.accountNumber}
          </Link>
        ),
        accountName: right.subscriber?.name,
        name: (
          <Link
            to={`/polestar/subscribers/${right.subscriber?.id}/level0_resources/${right.level0ResourceId}/extraction_rights?name=${right.name}`}
            className="text-sm text-primary-2 underline"
          >
            {right.name}
          </Link>
        ),
        volume: formatVolume(right.volume, ""),
        waterClassName: right.waterClass?.name,
        level0WRSIdentifier: right.level0Resource?.identifier,
        createdAt: right.createdAt ? formatDate(new Date(right.createdAt)) : "",
        status: (
          <Tag
            status={
              right.isActive && !right.deletedAt && isLinked
                ? "success"
                : "error"
            }
          >
            {!isLinked
              ? t("extraction_right.not_connected_to_extraction_point")
              : right.deletedAt
              ? t("common.deleted")
              : right.isActive
              ? t("common.active")
              : t("common.inactive")}
          </Tag>
        ),
        actions: (
          <Select
            placeholder={t("common.actions")}
            options={options}
            onChange={(e) => {
              if (e?.value) {
                navigate(e.value);
              } else if (e?.callback && isFunction(e?.callback)) {
                e.callback();
              }
            }}
            value={null}
            menuPortalTarget={document.body}
            isSearchable={false}
            className="w-32"
          />
        ),
      };
    })
    .filter(
      (row: any) =>
        !filter?.extractionRightName ||
        row.name.toString().includes(filter?.extractionRightName)
    )
    .filter(
      (row: any) =>
        !filter?.subscriberNameOrAccNo ||
        row?.subscriber?.accountNumber
          .toString()
          .includes(filter?.subscriberNameOrAccNo) ||
        row?.subscriber?.name
          .toString()
          .toLowerCase()
          .includes(filter?.subscriberNameOrAccNo.toLowerCase())
    )
    .filter(
      (row: any) =>
        !filter?.level0ResourceId ||
        row.level0Resource.id === filter?.level0ResourceId
    )
    .filter((row: any) =>
      filter.status === "disconnected"
        ? row?.rightFractions.every((i: any) => !i.isLinked)
        : true
    )
    .sort((a: any, b: any) => +a.accountNumber - +b.accountNumber);

  const handleExtractionRightSelect = (ids: string[]) => {
    const selected = extractionRights?.filter((i: any) =>
      ids.some((id) => i.id === id)
    );

    setSelectedExtractionRightIds(selected.map((v: any) => v.id));
    setCanAmalgamate(getExtractionRightsCanAmalgamate(selected));
  };

  useEffect(() => {
    if (showDeletedRecords !== undefined) {
      getExtractionRights();
    }
  }, [showDeletedRecords, getExtractionRights]);

  return (
    <>
      <header className="space-y-4 relative z-20">
        <div className="flex flex-row justify-end gap-4">
          {checkPermissions(["CreateExtractionRight"]) && (
            <Link
              className="btn-secondary text-sm rounded"
              to={`/polestar/level1wrs/${level1Resource.id}/extraction_rights/create`}
            >
              {t("extraction_right.create.title")}
            </Link>
          )}
          {checkPermissions(["CreateAmalgamation"]) && (
            <button
              className="btn-secondary text-sm rounded"
              disabled={canAmalgamate !== "yes"}
              onClick={() => {
                setConfirmModalString(AdministrativeApprovalType.AME);
              }}
            >
              {t(
                "approval.subdivide_and_amalgamate.create.short_title_amalgamate"
              )}
            </button>
          )}
        </div>
        <form className="grid grid-cols-1 md:grid-cols-2 2xl:grid-cols-4 gap-4">
          <div>
            <Label htmlFor="subscriberNameOrAccNo">
              {t("subscriber.filter.account_number_or_name")}
            </Label>
            <SearchInput
              id="subscriberNameOrAccNo"
              onChange={(e) =>
                handleFilterChange("subscriberNameOrAccNo", e.target.value)
              }
              value={filter.subscriberNameOrAccNo || ""}
            />
          </div>
          <div>
            <Label htmlFor="extractionRightName">
              {t("extraction_right.filter_extraction_right_number")}
            </Label>
            <SearchInput
              id="extractionRightName"
              onChange={(e) =>
                handleFilterChange("extractionRightName", e.target.value)
              }
              value={filter.extractionRightName || ""}
            />
          </div>
          <div>
            <Label htmlFor="level0ResourceId">
              {t("level0wrs.filter_level0wrs")}
            </Label>
            <Select
              options={level1ResourceOptions}
              value={level1ResourceOptions.find(
                (option: any) => option.value === filter.level0ResourceId
              )}
              onChange={(e) => {
                handleFilterChange("level0ResourceId", e?.value);
              }}
              inputId="level0ResourceId"
              openMenuOnFocus
              isClearable
            />
          </div>
          <div>
            <Label htmlFor="status">
              {t("subscriber.filter_subscriber_status")}
            </Label>
            <Select
              options={statusOptions}
              value={statusOptions.find((i) => i.value === filter.status)}
              onChange={(e) => handleFilterChange("status", e?.value)}
              isClearable
            />
          </div>
          {checkPermissions(["DeleteExtractionRight"]) && (
            <div>
              <Label>{t("common.deleted_records")}</Label>
              <Switch
                checked={showDeletedRecords}
                onChange={setShowDeletedRecords}
                className={classNames(
                  showDeletedRecords ? "bg-primary-1" : "bg-gray-200",
                  "relative inline-flex h-6 w-11 flex-shrink-0 cursor-pointer rounded-full border-2 border-transparent transition-colors duration-200 ease-in-out focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2 my-2"
                )}
                disabled={isLoading}
              >
                <span
                  aria-hidden="true"
                  className={classNames(
                    showDeletedRecords ? "translate-x-5" : "translate-x-0",
                    "pointer-events-none inline-block h-5 w-5 transform rounded-full bg-white shadow ring-0 transition duration-200 ease-in-out"
                  )}
                />
              </Switch>
            </div>
          )}
        </form>
      </header>
      <Table
        containerClassName="rounded-none md:rounded-none text-sm"
        tableHeaderClassName="relative z-10"
        fields={fields}
        data={tableData}
        stickyHeader
        loading={isLoading}
        selectionKey="id"
        selectedKeys={selectedExtractionRightIds}
        onSelectionChange={handleExtractionRightSelect}
        disabledKeys={extractionRights
          ?.filter((er: any) => !er.isActive)
          .map((er: any) => er.id)}
      />
      <ConfirmModal
        open={
          confirmModalString === AdministrativeApprovalType.AME ||
          confirmModalString === AdministrativeApprovalType.SDE
        }
        onClose={() => {
          setConfirmModalString("");
        }}
        onConfirm={() => {
          if (confirmModalString === "") return;
          handleOnConfirm({
            type: confirmModalString,
            rightId:
              AdministrativeApprovalType.SDE === confirmModalString
                ? subdivisionRightId
                : undefined,
          });
        }}
        isSubmitting={isLoading}
      >
        {t(
          "approval.subdivide_and_amalgamate.create.step_3.confirm_modal.title",
          {
            context:
              confirmModalString === AdministrativeApprovalType.AME
                ? ExtractionRightApprovalType.Amalgamate
                : ExtractionRightApprovalType.Subdivide,
          }
        )}
      </ConfirmModal>

      <ConfirmUpdateExtractionRightStatusModal
        open={Boolean(updateExtractionRightStatus.id)}
        extractionRight={updateExtractionRightStatus}
        onClose={() => {
          setUpdateExtractionRightStatus({
            id: "",
            name: "",
            isActive: false,
          });
        }}
      />
    </>
  );
};

export default Level1WRSExtractionRightList;
