import React from "react";
import { sumBy } from "lodash";
import { useTranslation } from "react-i18next";

import Modal from "@components/layout/Modal";
import Heading from "@components/layout/Heading";
import Legend from "@components/form/Legend";
import Label from "@components/form/Label";
import TextInput from "@components/form/TextInput";
import SelectLevel1Resource from "@components/form/SelectLevel1Resource";
import AdjustLevel0ResourceWaterClassesTable from "@components/table/AdjustLevel0ResourceWaterClassesTable";
import ConfirmModal from "@components/shared/ConfirmModal";
import { useLevel0WRSContext } from "@context/Level0WRSContext";
import { useWaterClassContext } from "@context/WaterClassContext";
import { useAllAccountingPeriods } from "@hooks/query/useAllAccountingPeriods";
import { useUpdateAccountingPeriod } from "@hooks/mutation/useUpdateAccountingPeriod";
import { convertMLToLiter } from "@utils/convertUnits";
import { formatDateInput } from "@utils/formatDate";
import { formatVolume } from "@utils/formatVolume";
import { isValidationError } from "@utils/formError";
import { toastError, toastSuccess } from "@utils/toast";

const Level0WRSAccountingPeriodVolume = () => {
  const { t } = useTranslation();
  const {
    stepHelpers,
    handleChangeDetails,
    details,
    getInfoMessages,
    navigateForCancel,
    updatingLevel0WRS,
    specialAction,
    setSpecialAction,
    modifyStatuses,
  } = useLevel0WRSContext();
  const {
    doAdjustWaterClassVolumeDetails,
    setDoAdjustWaterClassVolumeDetails,
    setAdjustedWaterClassVolumeDetails,
    adjustedWaterClassVolumeDetails,
    validateAdjustedClassVolume,
  } = useWaterClassContext();
  const { mutateAsync: updateAccountingPeriodMutation, isLoading } =
    useUpdateAccountingPeriod();

  const totalWaterClassesVolume = sumBy(details.waterClasses, (i) => +i.volume);
  const isAdjustingClassBalanceMandatory =
    convertMLToLiter(+details.yield) < totalWaterClassesVolume;

  const validateYield = (yieldVolumeML: number) => {
    modifyStatuses({
      stepNumber: 2,
      fieldName: "adjustedClassVolume",
    });

    if (yieldVolumeML === 0) {
      modifyStatuses({
        stepNumber: 2,
        fieldName: "yield",
        infoType: "error",
        message: t("level0wrs.update.step_3.error_2") as string,
      });
      return;
    }

    const yieldVolumeLiter = convertMLToLiter(yieldVolumeML);
    if (yieldVolumeLiter < totalWaterClassesVolume) {
      modifyStatuses({
        stepNumber: 2,
        fieldName: "yield",
        infoType: "warning",
        message: t("level0wrs.update.step_3.warning_6", {
          volume: formatVolume(yieldVolumeLiter),
        }) as string,
      });
      return;
    }

    modifyStatuses({
      stepNumber: 2,
      fieldName: "yield",
    });
  };

  useAllAccountingPeriods({
    options: {
      enabled: !details.periodStart && !details.periodEnd,
      select: (data: any) => data?.[0],
      onSuccess: (currentAccountingPeriod: any) => {
        handleChangeDetails(
          "periodStart",
          new Date(currentAccountingPeriod.periodStart),
        );
        handleChangeDetails(
          "periodEnd",
          new Date(currentAccountingPeriod.periodEnd),
        );
      },
    },
    params: {
      level1ResourceId: details.level1WRS.id,
    },
  });

  const availableToDistribute =
    +details.yield -
    sumBy(adjustedWaterClassVolumeDetails.map((i) => +i.adjustedVolume));
  const isUpdating = Boolean(updatingLevel0WRS);

  const handleSubmit = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();

    if (
      isUpdating &&
      doAdjustWaterClassVolumeDetails &&
      availableToDistribute > 0
    ) {
      setSpecialAction("confirmUnallocatedVolume");
      return;
    }

    stepHelpers.goToNextStep();
  };

  const handleConfirmModifySchemeYear = async () => {
    try {
      const { periodStart = new Date(), periodEnd = new Date() } = details;
      await updateAccountingPeriodMutation({
        periodId: details.accountingPeriodId,
        periodStart,
        periodEnd,
      });
      toastSuccess(
        t("date.accounting_period.toast.updated", {
          level1wrsName: details.level1WRS.name,
        }),
      );
    } catch (error: any) {
      const data = error?.response?.data;
      const errorMessages = isValidationError(error)
        ? data?.errors?.map((i: any) => i?.message)
        : [data?.message];
      toastError(
        <>
          <p>{t("date.accounting_period.toast.error_updating")}</p>
          {errorMessages.length > 0 && (
            <ul className="list-disc pl-4">
              {errorMessages.map((text: any) => {
                return <li key={text}>{text}</li>;
              })}
            </ul>
          )}
        </>,
      );
    }
    setSpecialAction("");
  };

  return (
    <>
      <form
        className="flex flex-col gap-6 p-6 grow justify-between"
        onSubmit={handleSubmit}
      >
        <div className="space-y-4">
          <Heading light>{t("level0wrs.create.year_and_volume")}</Heading>

          <fieldset className="space-y-4">
            <Legend>{t("level0wrs.create.enter_year")}</Legend>

            <div>
              <Label htmlFor="year_start">
                {t("level0wrs.create.enter_year_start")}
              </Label>
              <TextInput
                id="year_start"
                type="date"
                className="max-w-md"
                value={
                  details.periodStart
                    ? formatDateInput(details.periodStart)
                    : ""
                }
                onChange={(e) =>
                  handleChangeDetails("periodStart", new Date(e.target.value))
                }
                required
                disabled
              />
            </div>

            <div>
              <Label htmlFor="year_end">
                {t("level0wrs.create.enter_year_end")}
              </Label>
              <TextInput
                id="year_end"
                type="date"
                className="max-w-md"
                value={
                  details.periodEnd ? formatDateInput(details.periodEnd) : ""
                }
                onChange={(e) =>
                  handleChangeDetails("periodEnd", new Date(e.target.value))
                }
                min={
                  details.periodStart
                    ? formatDateInput(details.periodStart)
                    : ""
                }
                required
                disabled
              />
            </div>
          </fieldset>

          <fieldset className="space-y-4">
            <Legend>{t("level0wrs.create.enter_volume")}</Legend>
            <div>
              <Label htmlFor="level0wrs_volume">
                {t("level0wrs.create.enter_volume")}
              </Label>
              <TextInput
                id="level0wrs_volume"
                type="number"
                step="0.001"
                min={0}
                className="max-w-md"
                value={details.yield}
                placeholder="0.000"
                suffix={t("common.volume_unit")}
                onChange={(e) => {
                  handleChangeDetails("yield", e.target.value);
                  validateYield(+e.target.value);
                }}
                required
              />
            </div>
          </fieldset>

          {isUpdating && (
            <AdjustLevel0ResourceWaterClassesTable
              waterClasses={details.waterClasses}
              nextYearLevel0wrsVolume={+details.yield}
              level0wrsIdentifier={details.identifier}
              hasValidationError={getInfoMessages("error", 2).length > 0}
              currentYearLevel0wrsVolume={updatingLevel0WRS.yield}
              doAdjustWaterClassVolumeDetails={doAdjustWaterClassVolumeDetails}
              setDoAdjustWaterClassVolumeDetails={
                setDoAdjustWaterClassVolumeDetails
              }
              adjustedWaterClassVolumeDetails={adjustedWaterClassVolumeDetails}
              setAdjustedWaterClassVolumeDetails={
                setAdjustedWaterClassVolumeDetails
              }
              validateAdjustedClassVolume={validateAdjustedClassVolume}
              isAdjustingClassBalanceMandatory={
                isAdjustingClassBalanceMandatory
              }
            />
          )}
        </div>

        <footer className="flex gap-4 -mx-6 mt-6 p-6 pb-0 border-t border-gray-200">
          <button
            type="button"
            className="btn-outline-primary"
            onClick={stepHelpers.goToPrevStep}
          >
            {t("common.prev_step")}
          </button>
          <button
            type="submit"
            className="btn-primary"
            disabled={
              getInfoMessages("error", 2).length > 0 ||
              isLoading ||
              availableToDistribute < 0 ||
              (isAdjustingClassBalanceMandatory && availableToDistribute < 0)
            }
          >
            {t("common.next_step")}
          </button>
          <button
            type="button"
            className="btn-outline-primary"
            onClick={navigateForCancel}
          >
            {t("common.cancel")}
          </button>
        </footer>
      </form>

      <Modal open={specialAction === "modifySchemeYear"}>
        <div className="flex flex-col">
          <div className="border-b p-4">
            <h3 className="text-lg font-bold leading-6 text-gray-900">
              {t("level0wrs.update.step_3.action_1")}
            </h3>
          </div>
          <form className="p-4">
            <SelectLevel1Resource
              className="max-w-md py-4"
              isDisabled
              value={details.level1WRS.id}
            />
            <fieldset className="space-y-4">
              <div>
                <Label htmlFor="year_start">
                  {t("level0wrs.create.enter_year_start")}
                </Label>
                <TextInput
                  id="year_start"
                  type="date"
                  className="max-w-md"
                  value={
                    details.periodStart
                      ? formatDateInput(details.periodStart)
                      : ""
                  }
                  onChange={(e) =>
                    handleChangeDetails("periodStart", new Date(e.target.value))
                  }
                  required
                />
              </div>

              <div>
                <Label htmlFor="year_end">
                  {t("level0wrs.create.enter_year_end")}
                </Label>
                <TextInput
                  id="year_end"
                  type="date"
                  className="max-w-md"
                  value={
                    details.periodEnd ? formatDateInput(details.periodEnd) : ""
                  }
                  onChange={(e) =>
                    handleChangeDetails("periodEnd", new Date(e.target.value))
                  }
                  min={
                    details.periodStart
                      ? formatDateInput(details.periodStart)
                      : ""
                  }
                  required
                />
              </div>
            </fieldset>
          </form>
          <div className="mt-5 border-t px-4 py-2 flex flex-row-reverse gap-4">
            <button
              type="button"
              className="btn-primary"
              onClick={() => setSpecialAction("confirmModifySchemeYear")}
            >
              {t("common.modify")}
            </button>

            <button
              type="button"
              className="btn-outline-primary"
              onClick={() => setSpecialAction("")}
            >
              {t("common.cancel")}
            </button>
          </div>
        </div>
      </Modal>
      <ConfirmModal
        open={specialAction === "confirmModifySchemeYear"}
        onClose={() => setSpecialAction("")}
        onConfirm={handleConfirmModifySchemeYear}
        isSubmitting={isLoading}
        confirmText={t("common.ledger") as string}
      >
        {t("level0wrs.update.step_3.confirm_modal_title_2", {
          level1wrsName: details.level1WRS.name,
        })}
      </ConfirmModal>
      <ConfirmModal
        open={specialAction === "confirmUnallocatedVolume"}
        onClose={() => setSpecialAction("")}
        onConfirm={() => {
          setSpecialAction("");
          stepHelpers.goToNextStep();
        }}
        isSubmitting={isLoading}
        confirmText={t("common.continue") as string}
      >
        {t("level0wrs.update.step_3.confirm_modal_title_1", {
          volume: formatVolume(convertMLToLiter(availableToDistribute)),
          level0wrsIdentifier: details.identifier,
        })}
      </ConfirmModal>
    </>
  );
};

export default Level0WRSAccountingPeriodVolume;
