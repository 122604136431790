import { useState } from "react";
import { useTranslation } from "react-i18next";

import ApplicationActionButtons from "@components/form/ApplicationActionButtons";
import Modal from "@components/layout/Modal";
import { formatDate } from "@utils/formatDate";
import { formatVolume } from "@utils/formatVolume";
import { formatAccountingPeriod } from "@utils/formatAccountingPeriod";
import Tag from "@components/shared/Tag";
import {
  AdministrativeApprovalNumericType,
  ApprovalExtractionRightVersion,
  ExtractionRightApprovalType,
} from "@services/administrativeApprovals";
import { useNavigate } from "react-router-dom";
import { getStatusAndText } from "@utils/administrativeApproval";
import EvidenceList from "@components/shared/EvidenceList";

type Props = {
  data?: any;
  onClose: () => void;
  refetch?: () => void;
};
const AmalgamateOrSubdivideModal: React.FunctionComponent<Props> = ({
  data,
  onClose,
}: Props) => {
  const { t } = useTranslation();
  const [confirmationModal, setConfirmationModal] = useState("");
  const navigate = useNavigate();
  const context =
    +data?.type === AdministrativeApprovalNumericType.AME
      ? ExtractionRightApprovalType.Amalgamate
      : ExtractionRightApprovalType.Subdivide;

  const inputExtractionRights = data?.extractionRights.filter(
    (e: any) => +e.version === ApprovalExtractionRightVersion.Old,
  );

  const { status = "default", text: statusText } = getStatusAndText(
    data?.status,
  );

  return (
    <>
      <Modal open={data && confirmationModal === ""}>
        <div className="flex flex-col">
          <div className="border-b p-4">
            <h3 className="text-lg font-bold leading-6 text-gray-900 capitalize">
              {t("approval.subdivide_and_amalgamate.long_title", { context })}
            </h3>
          </div>
          <div className="p-4">
            <dl className="grid grid-cols-2 gap-x-4 gap-y-2 sm:grid-cols-1 mb-4">
              <div className="col-span-2 sm:col-span-1 flex gap-1">
                <dt className="font-medium text-gray-900">
                  {t("common.level1wrs")}:
                </dt>
                <dd className="text-gray-500">{data?.level1Resource.name}</dd>
              </div>
              <div className="col-span-2 sm:col-span-1 flex gap-1">
                <dt className="font-medium text-gray-900">
                  {t("common.accounting_period")}:
                </dt>
                <dd className="text-gray-500">
                  {formatAccountingPeriod(data?.accountingPeriod)}
                </dd>
              </div>
              <div className="col-span-2 sm:col-span-1 flex gap-1">
                <dt className="font-medium text-gray-900">
                  {t("common.subscriber")}:
                </dt>
                <dd className="text-gray-500">{data?.buyer?.name}</dd>
              </div>
              <div className="col-span-2 sm:col-span-1 flex gap-1">
                <dt className="font-medium text-gray-900">
                  {t("common.extraction_rights")}:
                </dt>
                <dd className="text-gray-500">
                  {inputExtractionRights?.map((er: any) => er.name).join(", ")}
                </dd>
              </div>
              <div className="col-span-2 sm:col-span-1 flex gap-1">
                <dt className="font-medium text-gray-900">
                  {t("extraction_right.total_volume")}:
                </dt>
                <dd className="text-gray-500">{formatVolume(data?.volume)}</dd>
              </div>
              <div className="col-span-2 sm:col-span-1 flex gap-1">
                <dt className="font-medium text-gray-900">
                  {t("common.status")}:
                </dt>
                <dd className="text-gray-500">
                  <Tag status={status}>{statusText}</Tag>
                </dd>
              </div>
              <div className="col-span-2 sm:col-span-1 flex gap-1">
                <dt className="font-medium text-gray-900">
                  {t("approval.seasonal_water_assignments.timestamp")}:
                </dt>
                <dd className="text-gray-500">
                  {formatDate(
                    new Date(
                      data?.status === 2
                        ? data?.approvedAt
                        : data?.status === 3
                          ? data?.rejectedAt
                          : data?.status === 4
                            ? data?.updatedAt
                            : data?.lodgedAt,
                    ),
                  )}
                </dd>
              </div>
              <div className="col-span-2 sm:col-span-1 flex gap-1">
                <dt className="font-medium text-gray-900">
                  {t("common.effective_date")}:
                </dt>
                <dd className="text-gray-500">
                  {formatDate(new Date(data?.effectiveDate))}
                </dd>
              </div>
            </dl>
          </div>

          {data?.evidences?.length ? (
            <EvidenceList data={data.evidences} />
          ) : null}

          <ApplicationActionButtons
            status={data?.status}
            onCancel={() => {
              setConfirmationModal("");
              onClose();
            }}
            onContinue={() => {
              navigate(
                `/polestar/administrative_approvals/amalgamate_or_subdivide?administrativeApprovalId=${data?.id}`,
              );
            }}
          />
        </div>
      </Modal>
    </>
  );
};

export default AmalgamateOrSubdivideModal;
