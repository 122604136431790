import { useTranslation } from "react-i18next";
import Heading from "@components/layout/Heading";
import React from "react";
import Label from "@components/form/Label";
import TextInput from "./TextInput";
import { formatDateInput } from "@utils/formatDate";
import { useAllAccountingPeriods } from "@hooks/query/useAllAccountingPeriods";
import Loading from "@components/shared/Loading";

type EffectiveDateFormProps = {
  level1ResourceId: string;
  effectiveDate?: Date;
  onDateChange: (date: Date) => void;
  onNext: () => void;
  onPrevious: () => void;
  onCancel: () => void;
  showHeadAndFooter?: boolean;
};

const EffectiveDateForm: React.FunctionComponent<EffectiveDateFormProps> = ({
  level1ResourceId,
  effectiveDate,
  onDateChange,
  showHeadAndFooter,
  onNext,
  onPrevious,
  onCancel,
}) => {
  const { t } = useTranslation();

  const isInitialFetch = React.useRef(true);
  const [disableEffectiveDate, setDisableEffectiveDate] = React.useState(true);

  const { data: currentAccountingPeriod } = useAllAccountingPeriods({
    options: {
      enabled: Boolean(level1ResourceId),
      select: (data: any) => data?.[0],
      onSuccess: (data: any) => {
        if (!isInitialFetch.current) return;
        if (!data || !data.periodStart || !data.periodEnd) return;

        const periodStart = new Date(data.periodStart);
        const periodEnd = new Date(data.periodEnd);

        let effectiveDateToUse = effectiveDate || new Date();
        if (effectiveDateToUse < periodStart) {
          effectiveDateToUse = periodStart;
        } else if (effectiveDateToUse > periodEnd) {
          effectiveDateToUse = periodEnd;
        }

        const currentDate = new Date();
        if (currentDate >= periodStart && currentDate <= periodEnd) {
          setDisableEffectiveDate(false);
        } else {
          setDisableEffectiveDate(true);
        }

        onDateChange(effectiveDateToUse);
        isInitialFetch.current = false;
      },
    },
    params: { level1ResourceId, isActive: true },
  });

  if (!level1ResourceId) {
    return (
      <div className="py-20">
        <Loading />
      </div>
    );
  }

  return (
    <>
      <div className="flex flex-col p-6 grow justify-between">
        <div className="flex flex-col grow gap-6">
          {showHeadAndFooter && (
            <div className="space-y-4">
              <Heading light>{t("date.effective.set")}</Heading>
            </div>
          )}

          <form className="mb-2">
            <div className="max-w-xs">
              <Label htmlFor="effectiveDate">
                {t("date.effective.application")}
              </Label>
              <TextInput
                type="date"
                id="effectiveDate"
                min={
                  currentAccountingPeriod?.periodStart &&
                  formatDateInput(new Date(currentAccountingPeriod.periodStart))
                }
                max={
                  currentAccountingPeriod?.periodEnd &&
                  formatDateInput(new Date(currentAccountingPeriod.periodEnd))
                }
                value={
                  effectiveDate ? formatDateInput(effectiveDate) : undefined
                }
                onChange={(e) => onDateChange(new Date(e.target.value))}
                required
                disabled={disableEffectiveDate}
              />
            </div>
            <div className="grow" />
          </form>
        </div>
        {showHeadAndFooter && (
          <footer className="flex gap-4 -mx-6 mt-6 p-6 pb-0 border-t border-gray-200">
            {
              <button
                type="button"
                className="btn-outline-primary"
                onClick={onPrevious}
              >
                {t("common.prev_step")}
              </button>
            }

            {
              <button type="button" className="btn-primary" onClick={onNext}>
                {t("common.next_step")}
              </button>
            }

            {
              <button
                type="button"
                className="btn-outline-primary"
                onClick={onCancel}
              >
                {t("common.cancel")}
              </button>
            }
          </footer>
        )}
      </div>
    </>
  );
};

export default EffectiveDateForm;
