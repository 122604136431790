import classNames from "classnames";
import { useTranslation } from "react-i18next";

import Heading from "@components/layout/Heading";
import { formatDate } from "@utils/formatDate";
import { formatVolume } from "@utils/formatVolume";

type WaterClassSummaryProps = {
  name: string;
  extractionRightNames: string[];
  nominalVolume: number;
  announcedAllocation: number;
  allocationUsage: number;
  balance: number;
  transfer: number;
  specialAnnouncement: number;
  forwardDraw: number;
  rate: number;
};

const WaterClassSummary: React.FunctionComponent<WaterClassSummaryProps> = ({
  name,
  extractionRightNames,
  nominalVolume,
  announcedAllocation,
  allocationUsage,
  balance,
  transfer,
  specialAnnouncement,
  forwardDraw,
  rate,
}) => {
  const { t } = useTranslation();

  const availableWater = [
    {
      label: t("subscriber.announced_allocations"),
      value: announcedAllocation,
      show: true,
    },
    {
      label: t("subscriber.approved_transfers"),
      value: transfer,
      show: transfer !== 0,
    },
    {
      label: t("subscriber.special_announcement"),
      value: specialAnnouncement,
      show: specialAnnouncement !== 0,
    },
    {
      label: t("subscriber.forward_draw_volume"),
      value: forwardDraw,
      show: forwardDraw > 0,
    },
  ].filter((i) => i.show);

  return (
    <div className="rounded-t-lg relative overflow-hidden">
      <header
        className="px-4 py-3 flex justify-between text-white"
        style={{
          backgroundColor: "#0091B3",
        }}
      >
        <Heading light className="text-inherit md:text-xl">
          {t("common.single_water_class")}: <span>{name}</span>
        </Heading>

        <span>
          {t("common.allocation")}: {rate}%
        </span>
      </header>

      <div className="grid grid-cols-12">
        <div className="col-span-4">
          <dl
            className="flex flex-col grow justify-between gap-4 p-4 rounded-bl-lg overflow-hidden"
            style={{
              backgroundColor: "#E2ECF2",
            }}
          >
            <div>
              <dt className="text-sm">{t("subscriber.extraction_rights")}: </dt>
              <dd className="text-xl text-primary-2 font-bold">
                {extractionRightNames.join(", ")}
              </dd>
            </div>
            <div>
              <dt className="text-sm mb-1">
                {t("extraction_right.total_volume")}
              </dt>
              <dd className="text-4xl text-primary-2 font-bold">
                {formatVolume(nominalVolume)}
              </dd>
            </div>
          </dl>
        </div>

        <div className="border border-t-0 p-4 flex flex-col gap-8 col-span-4">
          <dl className="space-y-2">
            {availableWater.map((i) => {
              return (
                <div
                  className="text-sm text-gray-500 flex justify-between"
                  key={"availableWater--" + i.label}
                >
                  <dt>{i.label}</dt>
                  <dd
                    className={classNames(
                      "text-lg  font-bold",
                      i.value < 0 ? "text-red-500" : "text-primary-2"
                    )}
                  >
                    {formatVolume(i.value)}
                  </dd>
                </div>
              );
            })}
          </dl>
          <dl>
            <dt className="text-sm">{t("subscriber.volume_available")}</dt>
            <dd className="text-3xl font-bold text-primary-2 mt-2">
              {formatVolume(announcedAllocation)}
            </dd>
          </dl>
        </div>

        <div className="border-r border-b p-4 gap-3 col-span-4 flex flex-col justify-between">
          <h3 className="text-sm flex justify-between text-gray-500">
            {t("subscriber.volume_used")}
            <span className="text-lg text-primary-2 font-bold">
              {formatVolume(allocationUsage)}
            </span>
          </h3>
          <div>
            <h3 className="text-sm">
              {t("subscriber.volume_remain", {
                today: formatDate(new Date()),
              })}
            </h3>
            <div
              className={classNames(
                "text-3xl font-bold mt-2",
                balance < 0 ? "text-red-500" : "text-primary-2"
              )}
            >
              {formatVolume(balance) || `0.000 ${t("common.volume_unit")}`}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default WaterClassSummary;
