import { Route, Routes } from "react-router-dom";

import CreateAdminTransfer from "@components/pages/polestar/administrative_approvals/admin_transfer/CreateAdminTransfer";
import CreateAmalgamationOrSubdivision from "@components/pages/polestar/administrative_approvals/amalgamation_or_subdivision/CreateAmalgamationOrSubdivision";
import ApprovalIndex from "@components/pages/polestar/administrative_approvals/ApprovalIndex";
import CreateStockAndDomestic from "@components/pages/polestar/administrative_approvals/CreateStockAndDomestic";
import CreateForwardDraw from "@components/pages/polestar/administrative_approvals/forward_draw/CreateForwardDraw";
import PermanentTransfer from "@components/pages/polestar/administrative_approvals/permanent_trade/PermanentTransfer";
import CreateSeasonalTransfer from "@components/pages/polestar/administrative_approvals/seasonal_transfer/CreateSeasonalTransfer";

const AdministrativeApprovalsRoutes = () => {
  return (
    <Routes>
      <Route index element={<ApprovalIndex />} />
      <Route path="permanent_trade" element={<PermanentTransfer />} />
      <Route path="approve_forward_draw" element={<CreateForwardDraw />} />
      <Route path="stock_and_domestic" element={<CreateStockAndDomestic />} />
      <Route path="create_admin_transfer" element={<CreateAdminTransfer />} />
      <Route path="seasonal_transfer" element={<CreateSeasonalTransfer />} />
      <Route
        path="amalgamate_or_subdivide"
        element={<CreateAmalgamationOrSubdivision />}
      />
    </Routes>
  );
};

export default AdministrativeApprovalsRoutes;
