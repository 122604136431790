import React from "react";
import { usePaginationState } from "@components/shared/DataTable/Pagination";
import { useTranslation } from "react-i18next";
import type { ColumnDef } from "@tanstack/react-table";

import DataTableRenderer from "@components/shared/DataTable/DataTableRenderer";
import ApprovalTableActionButtons from "@components/shared/ApprovalTableActionButtons";
import Tag from "@components/shared/Tag";
import IndeterminateCheckbox from "@components/form/IndeterminateCheckbox";
import { useAllAdministrativeApprovals } from "@hooks/query/zenith/useAllAdministrativeApprovals";
import { AdministrativeApprovalNumericType } from "@services/administrativeApprovals";
import {
  getActionTimestamp,
  getStatusAndText,
} from "@utils/administrativeApproval";
import { convertLiterToML } from "@utils/convertUnits";

const SeasonalTransferTable = () => {
  const { t } = useTranslation();
  const [pagination, setPagination] = usePaginationState();

  const {
    data: { administrativeApprovals, meta } = {
      administrativeApprovals: [],
      meta: {},
    },
    isLoading,
    isFetching,
  } = useAllAdministrativeApprovals({
    params: {
      page: pagination.pageIndex + 1,
      limit: pagination.pageSize,
      types: [AdministrativeApprovalNumericType.SWT],
    },
  });

  const { refetch: fetchAll, isFetching: isFetchingAll } =
    useAllAdministrativeApprovals({
      params: {
        types: [AdministrativeApprovalNumericType.SWT],
        limit: -1,
      },
    });

  const columns = React.useMemo<ColumnDef<any>[]>(
    () => [
      {
        id: "select",
        header: ({ table }) => (
          <IndeterminateCheckbox
            checked={table.getIsAllPageRowsSelected()}
            indeterminate={table.getIsSomeRowsSelected()}
            onChange={table.getToggleAllRowsSelectedHandler()}
          />
        ),
        cell: ({ table, row }) => {
          const selectedIds = table
            .getSelectedRowModel()
            .rows.map((row) => row.id);

          return (
            <IndeterminateCheckbox
              checked={selectedIds.includes(row.id)}
              disabled={!row.getCanSelect()}
              indeterminate={row.getIsSomeSelected()}
              onChange={row.getToggleSelectedHandler()}
            />
          );
        },
      },
      {
        accessorKey: "seller.name",
        header: t(
          "approval.seasonal_water_assignments.from_subscriber",
        ) as string,
      },
      {
        accessorKey: "buyer.name",
        header: t(
          "approval.seasonal_water_assignments.to_subscriber",
        ) as string,
      },
      {
        header: t("common.level0wrs") as string,
        cell: (info) => {
          const { extractionRights = [] } = info.row.original;
          return extractionRights.length
            ? extractionRights[0].level0Resource?.identifier
            : "-";
        },
      },
      {
        header: t("common.extraction_rights") as any,
        cell: (info) => {
          return info.row.original.extractionRights
            .map((i: any) => i.name)
            .join(", ");
        },
      },
      {
        accessorKey: "volume",
        header: t("common.volume") as string,
        cell: (info) => {
          const volume = info.getValue();
          return volume ? convertLiterToML(+volume) : 0;
        },
      },
      {
        accessorKey: "status",
        header: t("common.status") as string,
        cell: ({ row }) => {
          const { status } = row.original;
          const { status: tagStatus, text } = getStatusAndText(
            row.original.status,
          );
          return status ? <Tag status={tagStatus}>{text}</Tag> : null;
        },
      },

      {
        header: t("common.actioned_at") as string,
        cell: ({ row }) => {
          return getActionTimestamp(row.original);
        },
      },

      {
        id: "actions",
        header: t("common.actions") as string,
        cell: ({ row }) => <ApprovalTableActionButtons row={row} />,
      },
    ],
    [t],
  );

  return (
    <DataTableRenderer
      name={t("approval.seasonal_water_assignments.title")}
      columns={columns}
      data={administrativeApprovals}
      pagination={pagination}
      onPaginationChange={setPagination}
      pageCount={meta.lastPage ?? -1}
      isLoading={isLoading}
      isFetching={isFetching || isFetchingAll}
      fetchAll={fetchAll}
      fetchAllDataKey="administrativeApprovals"
      stickyHeader
    />
  );
};

export default SeasonalTransferTable;
