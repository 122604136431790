import api from "./api";

export const READING_CALCULATION = {
  CLICK_OVER: "click_over",
  BACKWARD: "backward",
  WATER_HARVESTING: "water_harvesting",
  METER_READING_ADJUSTMENT: "meter_reading_adjustment",
  DISTRIBUTION_LOSS: "distribution_loss",
} as const;

export type CreateDeclarationPayload = {
  reading: number;
  readAt: Date;
  isOpening: boolean;
  isEstimated: boolean;
  meterId: string; // meter.serialNo
  extractionPointName: string;
  calculation?: string;
  volume?: number;
};

export const createDeclaration = async (
  declaration: CreateDeclarationPayload
) => {
  const { data } = await api.post("/admin/declarations", declaration);

  return [data.declaration, data.workflowInstance];
};

export type GetAllDeclarationsParams = {
  level1ResourceId: string;
  periodStart: Date;
  periodEnd: Date;
  meterId: string;
  unmetered: boolean;
  extractionPointId: string;
  extractionPointIds: string[];
  readAt: Date;
  subscriberId: string;
};

export const getAllDeclarations = async (
  params?: Partial<GetAllDeclarationsParams>
) => {
  const { data } = await api.get("/admin/declarations", { params });

  return data.declarations;
};

type CreateInitialDeclarationPayload = {
  subscriberId?: string;
  extractionPointName: string;
  serialNo: string;
  reading: number;
  readAt: Date;
  isEstimated: boolean;
};

export const createInitialDeclaration = async (
  payload: CreateInitialDeclarationPayload
) => {
  const { data } = await api.post(
    "/admin/declarations/initialDeclaration",
    payload
  );

  return [data.declaration, data.workflowInstance];
};

type CreateMeterOnlyDeclaration = {
  serialNo: string;
  reading: number;
};

export const createMeterOnlyDeclaration = async (
  payload: CreateMeterOnlyDeclaration
) => {
  const { data } = await api.post(
    "/admin/declarations/meterOnlyDeclaration",
    payload
  );

  return [data.declaration, data.workflowInstance];
};

type CreateUnmeteredUsagePayload = {
  subscriberId: string;
  extractionPointId: string;
  volume: number;
  readAt: Date;
};

export const createUnmeteredUsage = async (
  payload: CreateUnmeteredUsagePayload
) => {
  const { data } = await api.post(
    "/admin/declarations/unmeteredUsage",
    payload
  );

  return [data.declaration, data.workflowInstance];
};
